<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>
			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="资讯标题" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="文章类型" width="80px">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">文章</span>
					<span v-if="scope.row.type == 2">快讯</span>
					<span v-if="scope.row.type == 3">日历</span>
					<span v-if="scope.row.type == 4">产业</span>
					<span v-if="scope.row.type == 5">专栏</span>
					<span v-if="scope.row.type == 7">活动</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="label_name" label="标签" min-width="15%">
			</el-table-column> -->
			<el-table-column prop="category_name" label="分类" width="60px">
			</el-table-column>
			<el-table-column prop="nickname" label="作者" width="140px">
			</el-table-column>
			<el-table-column prop="visits_num" label="阅读量" width="60px">
			</el-table-column>
			<el-table-column prop="release_time" label="发布时间" width="150px">
			</el-table-column>
			<el-table-column label="预览" width="120px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="showPreView(scope.row)">
						预览
					</span>
					<span class="pointer margin_left_10" @click="copyUrl(scope.row)">
						复制链接
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="250px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="push(scope.row)">
						{{ scope.row.is_push == 1 ? "已推送" : "推送" }}
					</span>
					<span
						class="pointer margin_left_10"
						@click="disInformation(scope.row)"
					>
						{{ scope.row.status == 1 ? "屏蔽" : "取消屏蔽" }}
					</span>
					<span class="pointer margin_left_10" @click="addTgPush(scope.row)">
						tg推送
					</span>
					<span class="pointer margin_left_10" @click="toEdit(scope.row)">
						修改
					</span>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)">
						删除
					</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import {
	disInformation,
	delInformation,
	informationHome,
	addInformation,
	addTgPush,
} from "@/api/article";
import { addPush } from "@/api/user";
import clipboard from "clipboard";
import ChineseConv from "chinese-s2t";

export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		disInformation(row) {
			disInformation({ id: row.id }).then((res) => {
				if (res.code == 200) {
					console.log(this.$parent);
					this.$parent.$parent.getDataList();
				}
			});
		},
		addTgPush(row) {
			if (row.allow_tgpush == 1) {
				addTgPush({
					id: row.id,
					title: row.title,
					content: row.content,
					prohibit_tran: row.prohibit_tran,
				}).then((res) => {
					if (res.code == 200) {
						console.log(this.$parent);
						this.$parent.$parent.getDataList();
						this.$message({ message: "推送成功", type: "success" });
					} else {
						this.$message({ message: "推送失败", type: "info" });
					}
				});
			} else {
				this.$message({ message: "该资讯不允许推送tg", type: "info" });
			}
		},
		informationHome(row) {
			informationHome({ id: row.id }).then((res) => {
				if (res.code == 200) {
					console.log(this.$parent);
					this.$parent.$parent.getDataList();
				}
			});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toDetail(row) {
			this.$router.push({
				path: "/message/addmessage",
				query: { id: row.id, type: "detail" },
			});
		},
		toEdit(row) {
			this.$router.push({
				path: "/message/addmessage",
				query: { id: row.id, type: "edit" },
			});
		},
		push(row) {
			const that = this;
			if (row.is_push == 1) {
				this.$message({ message: "该资讯已推送", type: "error" });
				return;
			}
			this.$confirm("此操作将推送该条资讯, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					if (row.type == 2) {
						// 快讯
						var pushData = {
							title: "MetaEra",
							desc: ChineseConv.s2t(row.title),
							type: 2,
							object_id: Number(row.id),
						};
						addPush(pushData)
							.then((res) => {
								if (res.code == 200) {
									this.$message({ message: "推送成功", type: "success" });
									this.examine(row);
								} else {
									this.$message({ message: "推送失败", type: "info" });
								}
							})
							.catch((error) => {});
					} else {
						// 非快讯
						var pushData = {
							title: ChineseConv.s2t(row.title),
							desc: ChineseConv.s2t(
								row.illustrate != null && row.illustrate != ""
									? row.illustrate
									: row.content
							),
							type: 1,
							object_id: Number(row.id),
						};
						addPush(pushData)
							.then((res) => {
								if (res.code == 200) {
									this.$message({ message: "推送成功", type: "success" });
									this.examine(row);
								} else {
									this.$message({ message: "推送失败", type: "info" });
								}
							})
							.catch((error) => {
								this.$message.error(error.message);
							});
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消推送",
					});
				});
		},
		examine(row) {
			let that = this;
			let formData = {
				id: row.id,
				is_push: 1,
				type: row.type,
				score: 1,
				release_time: row.release_time,
				content: row.content,
			};
			addInformation(formData)
				.then((res) => {
					if (res.code == 200) {
						location.reload();
					} else {
					}
				})
				.catch((error) => {});
		},
		showPreView(row) {
			let url = "https://metaera.com.hk/gw_detail?id=" + row.id;
			if (row.type == 2) {
				url = "https://metaera.com.hk/kuaixun_wz?id=" + row.id;
			}
			window.open(url, "预览");
		},
		copyUrl(row) {
			let msg = "https://metaera.com.hk/gw_detail?id=" + row.id;
			if (row.type == 2) {
				msg = "https://metaera.com.hk/kuaixun_wz?id=" + row.id;
			}
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条资讯, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					delInformation({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style scoped></style>
